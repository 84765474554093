import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class ListProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataProject: [
                {
                    id: 1,
                    srcimg: 'https://yucaipa.org/wp-content/uploads/2021/03/DSCF7749.jpg',
                    title: 'Yucaipa',
                    category: 'Services / ',
                    subcategory: 'Live & Social',
                },
                {
                    id: 2,
                    srcimg: "https://static.wixstatic.com/media/7438d9_4e0faa1de3e24f4890cb03f28163970a~mv2.jpg/v1/fill/w_732,h_381,al_c,lg_1,q_80,enc_auto/7438d9_4e0faa1de3e24f4890cb03f28163970a~mv2.jpg",
                    // srcimg: "images/stage/image-02.png",
                    title: 'Unison',
                    category: 'Services / ',
                    subcategory: 'Healthcare',
                },
                {
                    id: 3,
                    srcimg: 'https://s7d2.scene7.com/is/image/TWCNews/cypress_college_thumbnailpng',
                    title: 'Compton College',
                    category: 'Services / ',
                    subcategory: 'Education',
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="iconbox-slider">
                        <ul className="slides" data-item="3" data-nav="false" data-dots="false" data-auto="true">
                            {
                                this.state.dataProject.map(data => (
                                    <li className="item" key={data.id}>
                                        <div className="featured-post">
                                            <img src={data.srcimg} alt="" style={{ width: 400, height: 220, objectFit: 'fill' }}/>
                                                <Link to={data.srcimg}><i className="fa fa-arrows-alt"></i></Link>
                                        </div>
                                        <div className="title-post">
                                            <Link to="/blog" onClick={() => {window.location.href="/blog"}}>{data.title}</Link>
                                        </div>
                                        <div className="category-post">
                                            <Link to="/portfolio-v3" onClick={() => {window.location.href="/portfolio-v3"}}>{data.category}</Link>
                                            <Link to="/portfolio-v3" onClick={() => {window.location.href="/portfolio-v3"}}>{data.subcategory}</Link>
                                        </div>
                                    </li>
                                ))
                            }
                            </ul>
                            {/* <!-- /.slides --> */}
                        </div>
                        <div className="clearfix">
                            
                        </div>
                    </div>
            </div>
        );
    }
}

export default ListProject;