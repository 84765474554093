import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <p>
                    We are a high quality firm that seek to provide customer top notch services.
                </p>
                <p>
                    We have worked with many clients from size small to large. Our primary wish is to have our customers build their business with complete sastifactions and confidence. 
                </p>	
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;