import React, { Component } from "react";
// import FormApply from "./FormApply";
import {Link} from 'react-router-dom'


class Callback extends Component {
	constructor(props) {
		super(props);
		this.state = {
			titleCallback: [
				{
					id: 1,
					title: "Not Convince ?",
					text: "Please give us a call so we can answer any question you have ",
				},
			],
		};
	}
	render() {
		return (
			<section className="flat-row flat-callback">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{this.state.titleCallback.map((data) => (
								<div className="text-center" key={data.id}>
									<h2 style={{ color: "white" }}>{data.title}</h2>
									<div
										className="text-callback-content"
										style={{ color: "white" }}
									>
										{data.text} 
                                        <Link to="+16266792681" title="phone" style={{color: "#ffc107"}}>+1 626 679 2681</Link>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Callback;
